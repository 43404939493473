

main.home {
  // border-radius: 5px;
  background-color: black;
  text-align: center;
  opacity: 1;

  h1 {
    // font-family: 'Great Vibes', cursive;
    font-size: 3em;
    font-weight: bold;
  }
  .banner {
    width: 90%;
    height: auto;
  }

  .featured {
    h3.header {
      color: #fff;
    }

    img {
      max-width: 99%;
    }
  }
}


.basic-gallery {
  display: flex;
  justify-content: space-around;


  img {
    margin: 1em;
    cursor: pointer;
  }

}



