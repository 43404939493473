@import url('https://fonts.googleapis.com/css?family=Great+Vibes|Gotu&display=swap');
@import 'colors';

body {
  // background-color: $base-bkg;
  background-color: #fff;
  color: $base-text;
  font-family: Gotu, sans-serif;
}

h1, h2, h3, h4, h5, h6, header {
  // font-family: 'Great Vibes', cursive;
  color: $base-header;
}

.test {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1vw;
  color: #600;
}

.error {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  background-color: #f00;
  color: #000;
  padding: 1px;
}

// GLOBAL CLASSES
.todo {
  display: none;
}

.dev-msg {
  display: none;
}

.no-data {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
  color: $nodata-text;
  background-color: transparent;
}

.loading {
  background-color: black;
  color: #ff0;
  font-weight: bold;
  padding: 2px 2em;
  border: 1px black solid;
  border-radius: 4px;
}


.content {
  margin: 0 auto;
  // padding: 10px;
  min-height: 75vh;


  header {
    // font-size: 3em;
    text-align: center;
    margin: 0.2rem;

  }

}


.toolbar {
  text-align: center;
  margin: 0.5em 0;
  .max {
    width: 100%;
  }
}


.page-container {
  position: relative;
  min-height: 100vh;
  text-align: center;
  // background-color: #000;
}
.content-wrapper {
  padding-bottom: 2rem;
  // background-color: #000;
}


.tab-navs {
  .nav-item {
    a.nav-link {
      color: #003;

      &.active {
        color: #495057;
      }
    }
  }
}
