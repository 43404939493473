

.page-title {
  font-size: 3vh;
  padding-top: 1vh;
}

.contact {
  text-align: left;
  margin: 0 3em;
  // border: thin solid #999;
  border-radius: 0.3em;

  
  .nav-item {
    .nav-link {
      font-size: 2vw;
    }
  }
}



.contact-links {
  margin: 1vh 1vw;
  padding: 2vw;
  background-color: #ddd;
  border-radius: 0.3em;
  // overflow: hidden;

  .connect-text {
    border-radius: 0.2rem;
    background-color: #eee;
    padding: 1vw 2vw;
    margin: 0 2vw 1vh 2vw;
    color: #333;
    font-size: 1rem;
  }
}
