$menu-bkg: #efeeea;
$menu-text: #000;
$menu-item-border: #aaa;
$menu-active-bkg: #000;
$menu-active-text: #fff;
$menu-ext-link: #006;



.menu-wrapper {
  background-color: $menu-bkg;


  .navbar-nav {
    // display: inline;
    text-align: center;
    font-variant: small-caps;
    font-weight: bold;
    font-size: 2vw;
  }

  .nav-item {
    margin: 0 0.2vw;

    a {
      color: $menu-text;
      border: 1px solid transparent;
      border-radius: 3px;
      text-decoration: none;
      padding: 0 0.5vw;
  
      &:hover {
        border-color: $menu-item-border;
      }
  
      &.active, &.active:hover {
        border-color: $menu-active-bkg;
        background-color: $menu-active-bkg;
        color: $menu-active-text !important;
      }
  
      &.external-link {

        font-size: 2vw !important;
        display: inline-block;
        color: $menu-ext-link;
        svg {
          font-size: 0.5vw;
          display: inline;
        }
        &:hover {
          border-color: $menu-ext-link;
          color: $menu-ext-link;
          background-color: $menu-ext-link;
          color: $menu-active-text;
        }
      }

      &.btn.btn-outline-info {
        font-size: 2vw;
        display: inline-block;
        svg {
          font-size: 0.5vw;
          display: inline;
        }
      }
    
    }

  }

}



