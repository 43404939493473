
.image-detail {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.5em;
  background-color: black;
  padding: 1em 0;
  border-radius: 0.5em;

  max-width: 100%;

}

.toolbar-gallery-name {
  text-align: center;
  margin: 0.2rem auto;
  font-family: Gotu;
  font-size: 1.5em;
  font-weight: bold;
}
