
$nav-bkg: #ddd;
$nav-text: #000;
$nav-link-text: #111;
$nav-active-bkg: #222;
$nav-active-text: #fff;
$nav-hover-bkg: #fff;
$nav-hover-text: #000;

$album-bkg: #fff;
$album-text: #000;

$selected-album-bkg:#000;
$selected-album-text: #fff;


// filter bar - top row
.category-bar {
  background-color: $nav-bkg;
  font-variant: normal;
  font-size: 1em;
  padding: 2px 2em;

  a {
    color: $nav-text;
  }

  .browse-title {
    color: $nav-text;
    font-size: 1.5em;
    // font-family: 'Great Vibes', cursive;
    font-family: Gotu, sans-serif;
    text-align: center;
  }

  .filter-wrapper {
    // border: 1px solid $nav-text;
    border-radius: 5px;
    padding: 1px 0;
    text-align: center;

    .filters.list-group {
      margin-left: 0.5em;

      .navbar-text.lbl {
        // line-height: 1;
        font-size: small;
        padding-bottom: 0 !important;
        padding-top: 0.7rem !important;
      }

      // eg Location, Style, Medium
      .list-group-item.list-group-item-action {
        background-color: $nav-bkg;
        border-color: $nav-bkg;
        color: $nav-text;
        margin: 1px 2px;
        border-radius: 3px;

        a {
          color: $nav-text;
          text-decoration: none;
        }

        &:hover {
          background-color: $nav-hover-bkg;
          border-color: $nav-hover-bkg;
          color: $nav-hover-text;

          a, a:hover {
            color: $nav-hover-text;
            text-decoration: none;
          }
        }

        .active {
          background-color: $nav-active-bkg !important;
          border-color: $nav-active-bkg !important;
          color: $nav-active-text !important;

          &:hover {
            background-color: $nav-active-bkg;
            border-color: $nav-active-bkg;
            color: $nav-active-text;
          }
        }
      }

    }
  }


  
}

// thumbnails - 2nd row
.album-bar {
  

  .descript {
    z-index: 2;
    font-size: 0.8em;
    border: 1px solid;
    border-radius: 3px;
    text-align: center;
    padding: 4px;
    margin-top: 1.5em;
  }

  .category-desc {
    border-color: #000;
    float: right;

    .active-desc {
      color: #000;
    }
    .hover-desc {
      color: #555;
    }
  }

  .album-desc {
    float: left;
    color: #333;
    visibility: hidden;
    .hover-on {
      visibility: visible;
    }

  }
}
  
  
.albums {
  text-align: center;
  list-style-type: none;
  

  // eg Boston, Cambridge
  .album-btn {
    display: inline-block;
    border: outset thin #ddd;
    background-color: #ddd;
    padding: auto;
    margin: 2px;
    cursor: pointer;
    text-align: center;
    max-width: 10vw;
    

    img {
      max-width: 10vw;
      min-width: 50px;
      opacity: 0.8;
    }
  
    .album-name {
      font-family: 'Gotu', sans-serif;
      text-align: center;
      font-size: 80%;
      color: $album-text;
      // max-width: 10vw;
    }

    &.selected-nav {
      border: inset thin #000;
      background-color: $selected-album-bkg;
      color: $selected-album-text;
      opacity: 1;

      .album-name {
        color: $selected-album-text;
      }
    }
  }
  
  
}
