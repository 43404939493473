
// OVERRIDES?

$enable-shasows: true;
$enable-gradients: true;


$theme-colors: (
  "primary": #3a42af,
  "secondary": #06074b,
  "info": #7d1c9b,
  "warning": #e6b20a
);

@import "node_modules/bootstrap/scss/bootstrap";
