$base-bkg: #fff;
$base-text: #555;
$base-header: #000;

$todo-bkg: #000;
$todo-text: #ccc;

$error-bkg: #f00;
$error-text: #000;

$nodata-text: #f00;




