
.stacker {
  margin-bottom: 1em;
}

// from progress.scss
.help-text {
  padding: 5px;
  margin: 2px;
  border: 1px solid #999;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  font-size: small;
}
