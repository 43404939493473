
$footer-bkg: #000;;
$footer-text: #fff;

.footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 2rem;
  background-color: $footer-bkg;
  color: $footer-text;
  padding-top: 5px;
  
  .copyright {
    float: left;
    margin-left: 10px;
  }

  

}
