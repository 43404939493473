
.progress-view {
  // text-align: center;
  // background-color: #aaa;
  // border: thin ridge #444;
  // border-radius: 3%;
  // padding: 1em;

  .help-btn {
    float: right;
    // vertical-align: top;
    margin-bottom: 5px;
  }

  // header {
  //   background-color: #eee;
  //   border-radius: 3px;
  //   margin-bottom: 2px;
    
  // }
  .comp-title {
    font-variant: small-caps;
    font-size: 1em;
    font-weight: bold;
  }
}

.view-wrapper {
  // max-width: 25vw;
  text-align: center;
  margin: 0 auto;

  .help-text {
    padding: 5px;
    margin: 2px;
    border: 1px solid #999;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    font-size: small;
  }

  .img-wrapper {
    // margin: 1px auto;
    // border: thin ridge #333;
    border-radius: 3px;

    img {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }
}


.process-images {
  max-width: 100%;
}


.story {
  width: 100%;
  text-align: center;

  .progress-view {
    max-width: 35vw;
    margin: 0 auto;
  }
}
