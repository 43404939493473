
.current-mode {
  float: right;
  margin-right: 1em;

  &.text {
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    padding-bottom: 0.3em;
    margin-bottom: 0.2em;

    span {
      border-radius: 10px;
      padding: 0.2em 0.5em;
    }

    .development {
      background-color: #900;
      color: #fff;
      border: thin outset silver;
      cursor: pointer;
    }
    .test {
      background-color: #990;
      color: #000;
      border: 1px #990 solid;
    }
    .production {
      background-color: transparent;
      color: #000;
      font-variant: small-caps;
      border: none;
    }
  }
  
  &.icon {
    margin-top: 0.2em;
    
    .development {
      color: #900;
    }
    .test {
      color: #990;
    }
    .production {
      color: #000;
    }
  }

  

  

}

.dev-mode {
  .current-mode.text {
    .development {
      background-color: #0ff;
      color: #000;
      font-variant: small-caps;
      font-weight: bold;
    }
  }
  .current-mode.icon {
    .development {
      color: #066;
    }
  }
  
}
